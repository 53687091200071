












import { computed, defineComponent, watch } from "@vue/composition-api"
import { useClient } from "@/_use/client"

import Loader from "@/component/Loader.vue"
import ClientCreateUpdate from "../component/ClientCreateUpdate.vue"

export default defineComponent({
  name: "ClientUpdate",

  components: { Loader, ClientCreateUpdate },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const id = computed(() => props.id)

    const { client, clientId, errorMessage, isLoading } = useClient(id.value)

    watch(id, (newValue) => {
      clientId.value = newValue
    })

    return {
      client,
      errorMessage,
      isLoading,
    }
  },
})
